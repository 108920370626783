<template>
  <footer>
    <div class="container-fluid p-5">
      <div class="row first-footer">
        <div class="col-xs-12 col-md-2 stendardo">
          <img src="@/assets/img/Frame (1).svg" alt="" />
        </div>
        <div class="col-xs-12 col-md-8 content">
          <ul class="hideMobile">
            <li>
              <a href="https://goo.gl/maps/saEZT1A74WLzE7sX6" target="_blanck"
                >corso vittorio veneto 92 - ragusa</a
              >
            </li>
            <li>
              <a href="mailto:CONTATTI@SOGNALIBRO.IT">CONTATTI@SOGNALIBRO.IT</a>
              - <a href="tel:3892010096">+39 3892010096</a>
            </li>
            <!-- <li><a href="">facebook</a> - <a href="">instagram</a> -  <a href="https://www.booking.com/hotel/it/sognalibro-bed-and-books.it.html" target="_back">booking</a></li> -->
          </ul>
          <ul class="hideDesktop">
            <li>
              <a href="https://goo.gl/maps/saEZT1A74WLzE7sX6" target="_blanck"
                >corso vittorio veneto <br />
                92 - ragusa</a
              >
            </li>
            <li class="mb-5">
              <a href="mailto:CONTATTI@SOGNALIBRO.IT">CONTATTI@SOGNALIBRO.IT</a>
              <br />
              <a href="tel:3892010096">+39 3892010096</a>
            </li>
            <!-- <li><a href="">facebook</a></li>
            <li><a href="">instagram</a></li>
            <li><a href="https://www.booking.com/hotel/it/sognalibro-bed-and-books.it.html" target="_back">booking</a></li> -->
          </ul>
        </div>
        <div class="col-xs-12 col-md-2 testo">
          <img src="@/assets/img/sognalibro.svg" alt="" />
          <img src="@/assets/img/bab.svg" alt="" />
        </div>
        <div class="row second-footer">
          <div class="col-10 line"></div>
          <div class="col-12">
            <button>
              <a href="https://sognalibro.beddy.io/" target="_back">Book Now</a>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <LogoBar />
        <div class="policy">
          <a
            href="https://www.iubenda.com/privacy-policy/10164759"
            class="
              iubenda-white
              no-brand
              iubenda-noiframe iubenda-embed iubenda-noiframe
            "
            title="Privacy Policy "
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.iubenda.com/privacy-policy/10164759/cookie-policy"
            class="
              iubenda-white
              no-brand
              iubenda-noiframe iubenda-embed iubenda-noiframe
            "
            title="Cookie Policy "
            target="_blank"
          >
            Cookie Policy
          </a>
          <a href="#" class="iubenda-cs-preferences-link"
            >Tracking Preferences</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoBar from './LogoBar.vue';

export default {
  name: "Footer",
  components: {
    LogoBar
  }
};
</script>

<style></style>
